<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入banner名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="id" label="id" width="100"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column label="展示位置" width="120">
        <template slot-scope="scope"> {{typeOptions[scope.row.type]}} </template>
      </el-table-column>
      <el-table-column label="展示时间">
        <template slot-scope="scope"> {{scope.row.startTime | formatDate}} - <br> {{scope.row.endTime | formatDate}} </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="100"></el-table-column>
      <el-table-column prop="jumpConfig" label="跳转配置"></el-table-column>
      <el-table-column prop="img" label="图标" width="110">
        <template slot-scope="scope">
          <img :width="80" :src="scope.row.img" />
        </template>
      </el-table-column>
      <el-table-column prop="statusTxt" label="状态" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.status" class="success">上架</span>
          <span v-else class="warning">下架</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.status?'下架':'上架'}}</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}banner`" :visible.sync="addVisible" width="600px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleFormBox"
      >
        <el-form-item label="banner名称" prop="name" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="展示位置" prop="type" :rules="rules.changeRule">
          <el-select
            size="medium"
            clearable
            v-model="ruleForm.type"
            placeholder="展示位置"
          >
            <el-option
              v-for="(item,key) in typeOptions"
              :key="key"
              :label="item"
              :value="Number(key)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort" :rules="rules.blurRule">
          <el-input
            placeholder="序号大的在前"
            v-model="ruleForm.sort"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="banner描述">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="ruleForm.content">
            </el-input>
        </el-form-item> -->
        <el-form-item label="图片" prop="img" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.img}]"></uploadImg>
        </el-form-item>
        <el-form-item label="展示时间" prop="date" :rules="rules.changeRule">
          <el-date-picker
            v-model="ruleForm.date"
            @change="changeDate"
            value-format="timestamp"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="跳转json">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="ruleForm.jumpConfig">
            </el-input>
            <p>跳转配置说明：jumpType： 0-内部跳转，1-外部跳转</p>
        </el-form-item>
        <div class="subItem" v-if="ruleForm.type==2">
          <div class="subTitle">可见编辑</div>
          <el-form-item label="身份1">
            <el-checkbox v-model="ruleForm.visibleEditing.whiteList">白名单用户</el-checkbox>
            <el-checkbox v-model="ruleForm.visibleEditing.noWhiteList">非白名单用户</el-checkbox>
          </el-form-item>
          <el-form-item label="身份2">
            <el-checkbox v-model="ruleForm.visibleEditing.wallet">有钱包</el-checkbox>
          </el-form-item>
          <el-form-item label="国家组">
            <el-checkbox-group v-model="ruleForm.visibleEditing.countryGroupId">
              <el-checkbox :label="Number(key)" v-for="(item,key) in countryGroupOptions" :key="key">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <el-form-item label="状态" prop="status" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.status">
            <el-radio :label="1">上架</el-radio>
            <el-radio :label="0">下架</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      typeOptions:{
        '0':'语音房首页', 
        '1':'房间内ICON', 
        '2':'游戏banner'
      },
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      countryGroupOptions: {},
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
    this.getCountryGroupList();
  },
  methods: {
    async getCountryGroupList() {
      let res = await this.$http.countryGroupConfigList();
      if (res) {
        let countryGroup = res.data.filter(x=>x.id==200001)[0].configList
        this.countryGroupOptions = countryGroup.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
      }
    },
    changeDate(val){
      if(val&&val.length>0) {
        this.ruleForm.startTime = val[0]
        this.ruleForm.endTime = val[1]
      }
      console.log(this.ruleForm)
    },
    addFunc(){
      this.ruleForm = {date:[],visibleEditing: {whiteList:false,noWhiteList:false,wallet:false,countryGroupId:[]}}
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.img=val[0].url
      }
    },
    toEdit(data) {
      //编辑
      data.date = [data.startTime,data.endTime]
      this.ruleForm = {...data}
      if(!this.ruleForm.visibleEditing){
        this.ruleForm.visibleEditing = {whiteList:false,noWhiteList:false,wallet:false,countryGroupId:[]}
      } else{
        this.ruleForm.visibleEditing = JSON.parse(this.ruleForm.visibleEditing)
      }
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          if(ruleForm.type==2){
            ruleForm.visibleEditing = JSON.stringify(ruleForm.visibleEditing)
          }
          let res = await this.$http.banneSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.bannerList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    changeSort(val) {
      console.log(val)
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? 'ascend' : 'descend';
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
        this.searchForm.sortField = "";
      }
      this.getTableList(true);
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.banneDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    unmountFunc(row) {
      this.$confirm(`确定要${row.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.banneSave({ ...row , status:row.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.subItem{
  background: #f0f2f5;
  padding: 10px 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.subTitle{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>
